import translate from "@src/i18n"
import { formatNumberToCurrency } from "@utils/numbers"
import { Button, Col, Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"

import styles from "./style.module.less"

const CardPlanMobile = ({
  period,
  price,
  best,
  registerFormValues,
  plan,
  handlePageSelected,
  landingPage,
  isChangePlan,
  setCode,
  name,
  setCodePurchase,
  isPurchasePlan,
  isSmall,
}) => {
  const priceCardStyle = cx(
    {
      [styles.priceCard]: !best,
      [styles.priceCardChoice]: best,
    },
    styles.card__wrapper
  )
  console.log(isSmall)

  const renderButton = () => {
    return !isChangePlan ? (
      <SignBtn
        landingPage={landingPage}
        handlePageSelected={handlePageSelected}
        plan={plan}
        registerFormValues={registerFormValues}
        isSmall={isSmall}
      />
    ) : (
      <Button
        block
        className={
          isSmall ? styles.card__small__plan__button : styles.card__plan__button
        }
        type="primary"
        onClick={!isPurchasePlan ? setCode : setCodePurchase}
      >
        {translate("sign")}
      </Button>
    )
  }

  if (isSmall) {
    return (
      <div className={cx("cardPrice", styles.card__small)}>
        <div className={styles.card__small__plan__section}>
          <span className={styles.card__small__plan__period}>
            {!isChangePlan ? translate(period) : name}
          </span>
          <span className={styles.card__small__plan__description}>
            Renovação automática
            <br /> no cartão de crédito.
          </span>
        </div>
        <div className="flex flex-column">
          <div className="flex flex-row items-center justify-center">
            <span className={styles.card__small__plan__tag}>R$</span>
            <span className={styles.card__small__plan__price}>
              {formatNumberToCurrency(price)}
            </span>
          </div>
          {renderButton()}
        </div>
      </div>
    )
  }

  return (
    <div className={priceCardStyle}>
      <Row justify="center" className="items-center h-100">
        <Col span={16} className={styles.card__wrapper__col}>
          <div className={styles.card}>
            <span className={styles.card__plan__period}>
              {!isChangePlan ? translate(period) : name}
            </span>

            <span className={styles.card__plan__price}>
              {formatNumberToCurrency(price)}
            </span>

            <span className={styles.card__plan__description}>
              Renovação automática
              <br /> no Cartão de Crédito.
            </span>
            {renderButton()}
          </div>
        </Col>
      </Row>
    </div>
  )
}

const SignBtn = ({
  landingPage,
  handlePageSelected,
  plan,
  registerFormValues,
  isSmall,
}) => {
  const handleClickSign = () => {
    handlePageSelected({
      nextStepNumber: 3,
      plan,
      values: registerFormValues,
    })
  }

  if (landingPage) {
    return (
      <Link
        to="/cadastro-assinante"
        className={
          isSmall ? styles.card__small__plan__button : styles.card__plan__button
        }
      >
        {translate("sign")}
      </Link>
    )
  }

  return (
    <buttom
      block
      className={
        isSmall ? styles.card__small__plan__button : styles.card__plan__button
      }
      type="primary"
      onClick={handleClickSign}
    >
      {translate("sign")}
    </buttom>
  )
}

export default CardPlanMobile
