import React from "react"
import { Helmet } from "react-helmet"

import Offer from "../../modules/NewLandingPage/components/Offer"
import Page from "../../modules/NewLandingPage/components/Page"

const Planos = () => {
  return (
    <Page>
      <Helmet>
        <title>Planos - Anonimatta</title>
      </Helmet>
      <Offer className="section-border-top" />
    </Page>
  )
}

export default Planos
