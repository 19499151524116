import CardPlan from "@components/CardPlan"
import { useStores } from "@hooks/use-stores"
import {
  getActivePlans,
  getBestPlan,
  getPeriodFromPlan,
  getPriceFromPlan,
} from "@utils/plans"
import { Col, Row } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useCallback, useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "./Offer.module.less"

const Offer = ({ className }) => {
  const [plans, setPlans] = useState([])

  const isMobile = useMedia({
    maxWidth: 767,
  })

  const isDesktop = useMedia({
    minWidth: 1024,
  })
  const isSmall = useMedia({
    maxWidth: 768,
  })
  console.log(isSmall)
  const { registrationSubscriber, authSite } = useStores()

  const { listPlans } = registrationSubscriber

  useEffect(() => {
    // setIsClient(true)
    handleGetPlans()
  }, [])

  const handleGetPlans = useCallback(async () => {
    const response = await listPlans()
    const plans = _get(response, "plans", [])
    const activePlans = getActivePlans(plans)
    setPlans(activePlans)
  })

  return (
    <div className={cx(className, styles.section)}>
      <div className={cx("container", styles.container)}>
        <div className={cx("stage", styles.stage)}>Cancele quando quiser</div>
        <h2 className={cx("offer_title", styles.title)}>
          Pronto para começar?
        </h2>
        <div className={styles.text}>
          Pague com Cartão de Crédito ou PIX. <br />
        </div>

        {isMobile ? (
          <div className={cx("mc center", styles.landingPageContext)}>
            <div className="mt24 items-center">
              <Row justify="center" gutter={[12, 12]}>
                {_map(plans, (plan) => {
                  const { interval, amount, code } = plan

                  return (
                    <CardPlan
                      period={getPeriodFromPlan(interval)}
                      price={getPriceFromPlan(amount)}
                      best={getBestPlan(code)}
                      landingPage
                      isSmall={isSmall}
                    />
                  )
                })}
              </Row>
            </div>
          </div>
        ) : (
          <div className={cx("landingPageContext", styles.landingPageContext)}>
            <Row className="mb72">
              <div className="w-100 h-100 mc center mb72">
                <Row align="center" className="h-100 items-center">
                  <div className={cx("ph16 mt24", styles.paddingDesktop)}>
                    <Row
                      gutter={isDesktop ? 24 : [12, 12]}
                      className="items-center"
                    >
                      {_map(plans, (plan) => {
                        const { interval, amount, code } = plan

                        return (
                          <Col md={12} lg={6}>
                            <CardPlan
                              period={getPeriodFromPlan(interval)}
                              price={getPriceFromPlan(amount)}
                              best={getBestPlan(code)}
                              landingPage
                              isSmall={isSmall}
                            />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </Row>
              </div>
            </Row>
          </div>
        )}
      </div>
    </div>
  )
}

export default Offer
